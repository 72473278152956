import { Button } from "primereact/button";

export const createOrderFooter = (props) => {
  return (
     <div className="grid">
      {props?.fromscreen !== "autolog" && <div className={` col-12 md:col-6`}>
        <div className="grid">
          <div className={`col-12 md:col-4`}>
            <Button
              label="Send To D365"
              type="button"
              onClick={props?.onD365Click}
              disabled={!(props?.destination?.toLocaleLowerCase().includes("d365") && props?.processeData?.length > 0)}
              className={(props?.destination?.toLocaleLowerCase().includes("d365") && props?.processeData?.length > 0) ? `${props?.styles.primaryBtn}` : `${props?.styles.greyedButton}`}
            />
          </div>
          <div className={`col-12 md:col-4`}>
            <Button
              label="Send To Axapta"
              type="button"
              onClick={props?.onD365Click}
              disabled={!(props?.destination?.toLocaleLowerCase().includes("ax") && props?.processeData?.length > 0)}
              className={(props?.destination?.toLocaleLowerCase().includes("ax") && props?.processeData?.length > 0) ? `${props?.styles.primaryBtn}` : `${props?.styles.greyedButton}`}
            />
          </div>
          <div className={`col-12 md:col-3`}>
            <Button
              label="Cancel"
              type="button"
              onClick={props?.handleConfirmationDialog}
              className={`${props?.styles.tirnaryButton}`}
            />
          </div>
        </div>
      </div>}
    </div>
  );
};

export const orderSummaryFooter = (props) => {
  return (
    <div className="grid">
      <div className={`col-12 md:col-2`}>
        <Button
          label="Continue"
          type="button"
          onClick={props?.ongenerateXml}
          disabled={props?.disableButtons}
          className={`${props?.styles.primaryBtn}`}
        />
        </div>
        <div className={`col-12 md:col-2`}>
        <Button
          label="Cancel"
          type="button"
          onClick={props?.closeOrderSummary}
          className={`${props?.styles.tirnaryButton}`}
        />
      </div>
    </div>
  );
};
